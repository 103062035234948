/*********************************** 
* Variables                        *
************************************/
:root {
    --gray-border-color: #979797;
    --background-color-table: #D8D8D8;
}

/*********************************** 
* Style of home cards              *
************************************/
.homeCard {
    text-align: center !important;
    background-color: var(--background-color-table) !important;
    border: 1px solid var(--gray-border-color) !important;
    border-radius: 5% !important;
    min-width: 85% !important;
}

.homeCard button {
    padding: 16px !important;
}

.homeCard h5 {
    padding-bottom: 0 !important;
}