/*********************************** 
* Variables                        *
************************************/
:root {
    --background-color-modal-transparent: rgb(216 216 216 / 75%);
    --background-color-modal-without-transparent: #D8D8D8;
    --background-color-transparent: transparent;
    --border-color-modal: #EC7619;
    --black-color: #000000;
    --white-color: #FFFFFF;
}


/*********************************** 
* Style of Modal                   *
************************************/
.modalClient div {
    background-color: var(--background-color-transparent);
    border-radius: 40px !important;
}

.modalContent  {
    background-color: var(--background-color-modal-transparent) !important;
    border: 1px solid var(--border-color-modal);
    text-align: center !important;
}

.modalContent h3,
.modalContent h4 {
    padding-top: 148px !important;
}

.buttonsModal {
    border: none !important;
    background-color: var(--background-color-transparent) !important;
    padding-bottom: 148px !important;
}

.buttonsModalEvent button,
.buttonsModal button {
    margin-top: 35px !important;
    background-color: var(--black-color) !important;
}


/*********************************** 
* Style of Modal Event              *
************************************/
.modalContentEvent {
    background-color: var(--background-color-modal-without-transparent) !important;
    border: 1px solid var(--border-color-modal);
    text-align: center !important;
    border-radius: 40px !important;
}

.closeModalEvent {
    position: absolute !important;
    top: 25px !important;
    right: 25px !important;
    color: var(--white-color) !important;
    background-color: var(--black-color) !important;
    padding: 4px !important;
}

.buttonsModalEvent {
    border: none !important;
    background-color: var(--background-color-transparent) !important;
}

.formEvent div {
    background-color: var(--background-color-transparent) !important;
    border-radius: 0px !important;
}


/*********************************** 
* Style of responsive              *
************************************/
@media screen and (max-width: 576px) {
    .modalContent h4 {
        padding-top: 54px !important;
    }
    
    .buttonsModal {
        padding-bottom: 54px !important;
    }
}

@media screen and (max-width: 768px) {
    .modalContent h4 {
        padding-top: 74px !important;
    }
    
    .buttonsModal {
        padding-bottom: 74px !important;
    }
}