/*********************************** 
* Import CSS                       *
************************************/
@import url('./home.css');
@import url('./modal.css');
@import url('./login.css');


/*********************************** 
* Variables                        *
************************************/
:root {
  --white-color: #FFFFFF;
  --black-color: #000000;
  --last-breadcrumb: #828080;
  --gray-background-color: #D8D8D8;
  --gray-border-color: #979797;
  --white-background-color: #FFFFFF;
  --background-color-table: #D8D8D8;
  --orange-words: #F2720C;
  --indicator-value-obligatory: #FF0000;
}


/*********************************** 
* Style of Global page            *
************************************/
/* Body */
body {
  margin: 0 !important;
}

/* Orange Words */
.orangeWord {
  color: var(--orange-words) !important;
}

.text-danger {
  color: var(--indicator-value-obligatory);
}


/*********************************** 
* Style of Footer                  *
************************************/
.footer {
  text-align: center !important;
  padding-top: 50px !important;
  margin: 0 !important;
  max-width: 100% !important;
  background-color: var(--white-color) !important;
}


/*********************************** 
* Style of alerts                  *
************************************/
.alerts {
  width: calc(100% - 48px) !important;
  top: 24px !important;
  bottom: auto !important;
}


/*********************************** 
* Style of Tables                  *
************************************/
.bodyTableRows:nth-of-type(even) {
  background-color: var(--background-color-table) !important;
}

.bodyTableRows td {
  border-bottom: 1px solid var(--gray-border-color) !important;
}

.bodyTableRows td:first-child {
  border-left: 1px solid var(--gray-border-color) !important; 
}

.bodyTableRows td:last-child {
  border-left: 1px solid var(--gray-border-color) !important; 
  border-right: 1px solid var(--gray-border-color) !important;
  border-bottom: 1px solid var(--gray-border-color) !important;
}

.bodyTable tr:nth-child(2) td {
  border-top: 1px solid var(--gray-border-color) !important;
}

.headTableRow th {
  border-bottom: 1px solid var(--gray-border-color) !important;
  border-left: 1px solid var(--gray-border-color) !important;
}

.headTableRow th:first-child {
  border-left: none !important;
}

.separatorRow td {
  height: 7px !important;
  padding: 0 !important;
}


/*********************************** 
* Style of Button Actions          *
************************************/
.buttonActions button {
  padding: 0 !important;
}

.buttonActions img {
  background-color: transparent !important;
  color: var(--black-color) !important;
  height: 24px !important;
  width: 24px !important;
}


/*********************************** 
* Style of Breadcrumbs             *
************************************/
.breadcrumbs li,
.breadcrumbs li p {
  color: var(--black-color) !important;
}

.breadcrumbs li:last-child,
.breadcrumbs li:last-child p {
  color: var(--last-breadcrumb) !important;
}


/*********************************** 
* Style of Avatar                  *
************************************/
.avatarClient {
  background-color: var(--orange-words) !important;
  width: 112px !important;
  height: 112px !important;
}

/*********************************** 
* Style of Vehicle picture         *
************************************/
.vehiclePicture {
  border-radius: 10px !important;
  width: 95% !important;
  height: auto !important;
}


/*********************************** 
* Style of Inputs                  *
************************************/
.inputStyle input,
.inputStyle input:focus {
  color: var(--orange-words) !important;
}

.inputStyle div::after {
  border-bottom: 2px solid #F2720C !important;
}

/*********************************** 
* Style of documents card          *
************************************/
.cardDocuments {
  width: 100% !important;
  background-color: var(--gray-background-color) !important;
  border: 1px solid var(--gray-border-color) !important;
}

.cardDocuments button {
  flex-direction: column !important;
  color: var(--black-color) !important;
  font-size: 1rem !important;
}

