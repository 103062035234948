/*********************************** 
* Style of Logo Form               *
************************************/
.logoMain {
    width: 100%;
}

.logoBackground {
    width: 90% ;
    opacity: 36%;
    position: absolute;
}

.formLogin {
    position: relative;
}

.logoLoginMain {
    margin-top: 43px !important;
}

.textFieldLogin {
    width: 80% !important;
}

/*********************************** 
* Style of responsive              *
************************************/
@media screen and (max-width: 768px) {
    .logoMain {
        width: 70% !important;
    }

    .logoBackground {
        display: none;
    }

    .formLogin {
        justify-content: flex-start !important;
        align-items: center !important;
    }
}

@media screen and (max-width: 1024px) {
    .textFieldLogin {
        width: 55% !important;
    }

    .formLogin form {
        margin-top: 30px !important;
    }
}